import React from 'react';
import './Photos.css';
import ImageCarousel from '../imagecarousel/ImageCarousel';

const Photos = () => {



  return (
    <div className='photos-container'>
        <h2 className='photo-gallery-banner'>GALLERY</h2>
      <div className="video-wrapper">
          <h3>2021 AUDI R8</h3>
          <p >Fix and paint dent in the front fender and scratches in the bumper.</p>
          <video controls poster={require('../../images/audi-screenshot.png')}>
          <source className="video" src={require('../../videos/My Movie 9.mp4')} type="video/mp4" />
          </video>
      </div>
          <hr />
          <div className="video-wrapper">
              <h3 >2023 Porsche Macan</h3>
              <p >Fix and paint dents on passenger side rear door and panel.</p>
              <video controls poster={require('../../images/porsche-macan-screenshot.png')}>
              <source className="video" src={require('../../videos/porsche-macan-video.mp4')} type="video/mp4" />
              </video>
          </div>
          <hr />

          <div className="video-wrapper">
              <h3 >2021 Audi</h3>
              <p >Fix and paint scratches in rear bumper and replace missing trim.</p>
              <video controls poster={require("../../images/audi-bumper.png")}>
              <source className="video" src={require("../../videos/audi-bumper.mp4")} type="video/mp4" />
              </video>
          </div>
          {/* <hr /> */}
            

            {/* <div className="swiper">
                <h3 >2014 LEXUS ES 350</h3>
                <p >Pop out the dent without having to paint it.</p>
                <div className="swiper-wrapper">
                    <div className="swiper-slide"><img src="./images/red-lexus-dented-bumper.JPG"></div>
                    <div className="swiper-slide"><img src="./images/red-lexus-dented-bumper-after..JPG"></div>
                </div>
                <!-- Add Pagination -->
                <div className="swiper-pagination"></div>
                <!-- Add Arrows -->
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
            <hr> */}
            <hr />
            
            <ImageCarousel />

            {/* <hr /> */}
            
            {/* <div className="swiper">
                <h3 >2015 MERCEDES ML 350</h3>
                <p >Repair crease dent, paint and clear coat.</p>
                <div className="swiper-wrapper">
                    <div className="swiper-slide"><img src="./images/mercedes-ml-crease-before.jpg"></div>
                    <div className="swiper-slide"><img src="./images/mercedes-ml-crease-primer.jpg"></div>
                    <div className="swiper-slide"><img src="./images/mercedes-ml-crease-paint.jpg"></div>
                    <div className="swiper-slide">
                        <video controls muted poster="./images/mercedes-screenshot.png">
                            <source className="video" src="./videos/IMG_2158.MOV" type="video/mp4">
                        </video>
                    </div>
                </div>
                <!-- Add Pagination -->
                <div className="swiper-pagination"></div>
                <!-- Add Arrows -->
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div> */}
            
            <hr />
            
            <div className="video-wrapper">
                <h3>2021 BENTLEY GT MULLINER</h3>
                <p>Align front bumper and replace the clips</p>
                <video controls muted poster={require("../../images/bentley-bumper-screenshot.png")}>
                <source className="video" src={require("../../videos/My Movie 4.mp4")} type="video/mp4" />
                </video>
            </div>
            <hr />
            
            <div className="video-wrapper">
                <h3 >2016 MASERATI GHIBLI</h3>
                <p >Repair scratch in the front bumper, paint and clear coat..</p>
                <video controls poster={require("../../images/mazerati-screenshot.png")}>
                <source className="video" src={require("../../videos/My Movie 5.mp4")} type="video/mp4" />
                </video>
            </div>
            <hr />

            <div className="video-wrapper">
                <h3 >2015 CHEVY SILVERADO</h3>
                <p >Repair and paint dents and scratches on the side panel.</p>
                <video controls muted poster={require("../../images/pickup-truck-screenshot.png")}>
                <source className="video" src={require("../../videos/My Movie 6.mp4")} type="video/mp4" />
                </video>
            </div>
            <hr />
            
            {/* <div className="video-wrapper">
                <h3 >2019 BENTLEY GT CONVERTIBLE</h3>
                <p >Repair a scratch in the front bumper and header panel, paint and clear coat.</p>
                <video controls muted poster={require("../../images/gtc-screenshot.png")}>
                <source className="video" src={require("../../videos/My Movie 7.mp4")} type="video/mp4" />
                </video>
            </div>
            <hr /> */}
            
            <div className="video-wrapper">
                <h3 >2019 BENTLEY</h3>
                <p >Paint correction (3 stage) and fix a scratch in the front bumper, paint and clear coat.</p>
                <video controls muted poster={require("../../images/blue-bentley-screenshot.png")}>
                <source className="video" src={require("../../videos/My Movie 8.mp4")} type="video/mp4" />
                </video>
            </div>
            
    </div>
  )
}

export default Photos