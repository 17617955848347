import React from 'react'
import './home.css';
import About from '../About/About';
import Why from '../Why/Why';
import FAQ from '../FAQ/FAQ';
import MainVideo from '../MainVideo/MainVideo';



import { motion } from 'framer-motion';

const Home = () => {

  //Animates the vehicle icons when the page loads.
  const visible = { opacity: 1, x: 0, transition: { duration: 0.4 } };
    const itemVariants = {
      hidden: { opacity: 0, x: -710 },
      visible
    };


  

  return (
    
    <div className='header'>
      <div className='header-overlay'>
        <div className="vehicle-icons">
        <motion.div 
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 0.1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.2 } } }}
          className="vehicle-icons-inner-div-top" >
            
                <motion.img variants={itemVariants} src={require('../../images/tesla.png')} alt='Tesla logo'/>
                <motion.img variants={itemVariants} src={require('../../images/mercedes-icon.png')} alt='Mercedes logo'/>
                <motion.img variants={itemVariants} src={require('../../images/audi-icon.gif')} alt='Audi logo'/>
                <motion.img variants={itemVariants} src={require('../../images/porsche-icon.png')} alt='Porsche logo'/>
                <motion.img variants={itemVariants} src={require('../../images/bmw-icon.png')} alt='BMW logo'/>
                <motion.img variants={itemVariants} src={require('../../images/caddilic-icon.jpg')} alt='Cadillac logo'/>
            </motion.div>
        </div>
        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 2,
              delay: 1,
              ease: [0, 0.71, 0.2, 1.01]
            }}>
            <img className="company-logo" src={require('../../images/company-logo.png')} alt='Company Logo' />
          
        </motion.div>
        <motion.h1
          
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 2,
              delay: 1.5,
              ease: [0, 0.71, 0.2, 1.01]
            }}>
            Most body shops tell you days or weeks for a simple scratch or dent repair and charge you thousands.<br />
                            We can do the repairs in just a few hours, AND SAVE YOU THOUSANDS! 
          
        </motion.h1>
        
        <div className="vehicle-icons">
        <motion.div 
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 0.1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.2 } } }}
          className="vehicle-icons-inner-div-bottom" >
                <motion.img variants={itemVariants} src={require('../../images/toyota-icon.jpg')} alt='Toyota logo'/>
                <motion.img variants={itemVariants} src={require('../../images/lexus-icon.png')} alt='Lexus logo'/>
                <motion.img variants={itemVariants} src={require('../../images/jaguar-icon.jpg')} alt='Jaquar logo'/>
                <motion.img variants={itemVariants} src={require('../../images/chevy-icon.jpg')} alt='Infiniti logo'/>
                <motion.img variants={itemVariants} src={require('../../images/honda-icon.png')} alt='Honda logo'/>
                <motion.img variants={itemVariants} src={require('../../images/ford-icon.png')} alt='Lamborghini logo'/>
            </motion.div>
        </div>
        <div className="locations"><b className='theme-color'>SERVING:</b> Plano, Mckinney, Frisco and Carrolton</div>
        <br />
        </div>

        {<About />}
      {<MainVideo />}
      {<FAQ />}
      {<Why />}
    </div>
    
  )
}

export default Home