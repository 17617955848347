import React from 'react';
import './About.css';

const About = () => {
    

   


  return (
    <div>
        
        <div className="about">
            <div  className="about-us-text-container">

            
                <div className="about-banner ">ABOUT US</div>
                <p>
                    With over 20 years of experience in the auto body and paint industry, we have been proudly serving our valued customers with top-quality auto body repair solutions.
                    <br /><br />
                    What sets us apart is our mobile service. We bring our expertise directly to you, eliminating the hassle of transporting your vehicle to a traditional body shop. 
                    Our mobile units are fully equipped to handle a wide range of repairs, from minor scratches and dents, to moderate damage. 
                    Whether you're at home, work, or anywhere in between, we will come to your location and do the repairs on-site, saving you time and money and providing convenience that fits your busy schedule.
                    We know your time is valuable, so we make sure our team is always on time and do the repairs within the given time frame.
                    <br /><br />
                    
                    Call or text us now for a free estimate, and experience the convenience of our mobile service. 
                    
                </p>
            
                </div>
        </div>
        
        <div  className="services-container">
                    <div className='services'>
                <div className="services-banner">
                    OUR SERVICES
                </div>
                <p>
                    Dent Repair - Scratch Repair - Plastic Bumper Repair - Bumper Alignment - Crack Repair - Hole Repair -  
                Paint Correction - Rust Repair.
                </p>
                <br />
                <p><b>Add On Services: </b>Headlight Restoration - Cosmetic Rim Repair - Replacing Parts - Paint Chip Repair.  </p>
                <br />
                <p><b className='service-we-dont-do'>Services We Do Not Offer: </b>Paintless Dent Repair - Hail Damage Repair - Ding Repair.  </p>
            
            </div>
        </div>
    </div>
  )
}

export default About